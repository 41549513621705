import playIllustration from "../assets/images/play-illustration.png";

export default function Play() {
  return (
    <article className="influencer-container">
      <section className="games-container">
        <div>
          <img src={playIllustration} alt="Win $100 on funcomp" />
        </div>

        <div className="host-text-wrapper">
          <p className="slant-play-text">PLAY</p>
          <h4 className="influencer-content-title">
            A chance to win <br /> <span>$100</span> or more!
          </h4>
          <p className="influencer-content-dcrptn">
            PLAY, <span className="highlighted-text">Pay To Play</span> Games for a chance to win REAL MONEY! While some games pay out smaller amounts to winners, other games pay out $100 or MORE to WINNERs. Prize amounts increase depending on the competition and some  <span className="highlighted-text"> Pay To Play </span> Games pay out thousands to a single winner.  <span className="highlighted-text"> Pay To Play </span> Games start at $1.00 and increase depending on the game. Fun Comp also offers FREE options to play Non-Pay To Play Games that pay winners Virtual Prizes also known as Fun Coins. Download and play for a chance to win.
          </p>

          <div class="host-content-btns">
            <button class="host-apply-btn">Register</button>
          </div>
        </div>
      </section>
    </article>
  );
}
