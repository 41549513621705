import {  Route, Router, RouterProvider, Routes, createBrowserRouter } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home"; 

function App() {
  // initialize a browser router
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
  ])

  return (
      <RouterProvider router={router} />
  )
}

export default App;
