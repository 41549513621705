import React from 'react';
import influencerHero from '../assets/images/influencer-hero-img.png';
import '../styles/Influencer.css';
import { ReactComponent as Youtube } from '../assets/icons/youtube-faded.svg';
import { ReactComponent as Instagram } from '../assets/icons/insta-faded.svg';
import { ReactComponent as Twitter } from '../assets/icons/twitter-faded.svg';
import { ReactComponent as Facebook } from '../assets/icons/facebook-faded.svg';
import { ReactComponent as Tiktok } from '../assets/icons/tiktok-faded.svg';
import { ReactComponent as Edit } from '../assets/icons/edit.svg';
import { ReactComponent as Winner } from '../assets/icons/winner.svg';
import { ReactComponent as Second } from '../assets/icons/second-winner.svg';
import { ReactComponent as Third } from '../assets/icons/third-winner.svg';
import InputBG from '../assets/images/input-card-bg.png';
import WinnerPerson from '../assets/images/winner-person.png';
import SecondPerson from '../assets/images/second-person.png';
import ThirdPerson from '../assets/images/third-person.png';

function Influencer() {
  const inputRef = React.useRef(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const handleClick = () => {
    inputRef.current.focus();
    setIsOpen(!isOpen);
  };

  return (
    <div className="influencer-container">
      <div className="influencer-wrapper">
        <img
          className="influencer-hero"
          src={influencerHero}
          alt="influencer"
        />
        <div className="influencer-content">
          <div className="influencer-content-left">
            <div className="input-card">
              <img className="input-card-bg" src={InputBG} alt="circle" />
              <h4>Quiz</h4>
              <p className="quiz-qstn">
                Quick, draw the answer to <br /> 34 + 78!
              </p>
              <div className="input-box">
                <input
                  ref={inputRef}
                  onFocus={() => setIsOpen(true)}
                  onBlur={() => setIsOpen(false)}
                  className="draw-textarea"
                  type="text"
                  placeholder="Draw..."
                />
                <Edit
                  onClick={handleClick}
                  className={
                    isOpen ? 'invisible-edit-icon' : 'visible-edit-icon'
                  }
                />
              </div>
              <p className="quiz-pagination">2/10</p>
            </div>
            <div className="winner-box">
              <img src={WinnerPerson} alt="winner" />
              <p className="title">Winner</p>
              <p className="prize">$125</p>
              <Winner className="winner-icon" />
            </div>
            <div className="second-winner-box">
              <img src={SecondPerson} alt="second-winner" />
              <p className="title">75p 2nd</p>
              <p className="prize">$45</p>
              <Second className="second-icon" />
            </div>
            <div className="third-winner-box">
              <img src={ThirdPerson} alt="third-winner" />
              <p className="title">45p 3rd</p>
              <p className="prize">$25</p>
              <Third className="third-icon" />
            </div>
          </div>
          <div className="influencer-content-right">
            <h4 className="influencer-content-title">
              <span>Funcomp</span> is a Revolution for those looking for a chance to win FUN  MONEY.
            </h4>
            <p className="influencer-content-dcrptn">
            Play our high prize games for a chance to win BIG! Take a trip, pay your rent, car note, mortgage, student loans or spend your winnings on what ever you choose.
            </p>
            <div className="information-box">
              <p>
                40+ <br /> <span> Games</span>
              </p>
              <div className="hor-ln"></div>
              <p>
                4k+ <br /> <span>Members</span>
              </p>
              <div className="hor-ln"></div>
              <p>
                50+ <br /> <span>Daily Prizes</span>
              </p>
              <div className="hor-ln"></div>
              <p>
                80+ <br /> <span>Regions</span>
              </p>
            </div>
          </div>
        </div>

        <Youtube className="youtube-icon" />
        <Instagram className="instagram-icon" />
        <Tiktok className="tiktok-icon" />
        <Facebook className="facebook-icon" />
        <Twitter className="twitter-icon" />
      </div>
    </div>
  );
}

export default Influencer;
